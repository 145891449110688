import React from "react";
import { Link } from "gatsby"
import "../styles/global.css"
import linkedInIcon from '../img/LI-In-Bug-White.png';

export default ({ children }) => {
    const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <div className="professional-heading">
        <h1>Larissa Smith</h1>
        <p>Storyboard/Comic Artist, Animator, Web Developer</p>
        <p>Send professional inquires through my <a href="https://www.linkedin.com/in/larissa-smith-329a2385/">linkedIn</a> or to larissaduparis@gmail.com.</p>
      </div>
      <div className="main">
        <nav className="navigation" onClick={() => setExpanded(!expanded)}>
          <ul className={expanded ? "expanded" : ""}>
            <li><Link to="/portfolio">Animation</Link></li>
            <li><Link to="/portfolio/illustration">Illustration/Design</Link></li>
            <li><Link to="/portfolio/figures">Life Drawing</Link></li>
            <li><Link to="/">Comic</Link></li>
            <li className="linkedin"><a href="https://www.linkedin.com/in/larissa-smith-329a2385/"><img src={linkedInIcon}/></a></li>
          </ul>
        </nav>
        <div className="wavesImg">
          <div className="centeredImg"/>
        </div>
        {children}
      </div>
      <footer>©2020 by Larissa S</footer>
    </div>
  )
}