import React from 'react';
import PortfolioLayout from '../../components/PortfolioLayout.js';
import { illustrations } from '../../data/galleryData.js';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "../../styles/Gallery.css";

export default () => {
  const lightboxOptions = {
    buttons: {
      showDownloadButton: false
    }
  };


  return (  
    <SimpleReactLightbox>
      <PortfolioLayout>
        <div className="content gallery">
          <div className="aux-info">
            <div className="with-margin aux-info-item portfolio">
            <SRLWrapper options={lightboxOptions}>
                <h2>Illustration & Concept Art</h2>
                <div className="thumbnails-container scale-interaction">
                  {illustrations.map(file => (
                  <div className="art-thumb-container">
                    <div className="hover-overlay">
                      <p>{file.name}</p>
                    </div>
                    <a href={`/portfolio/${file.filename}.jpg`} data-attribute="SRL">
                      <img src={`/portfolio-thumbs/${file.filename}.jpg`} alt={file.description}/>
                    </a>
                  </div>))}
                </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </PortfolioLayout>
    </SimpleReactLightbox>
  );
}
